import React from "react"
import BlackTextVideoSection from "../components/BlackTextVideoSection/BlackTextVideoSection"
import DNASection from "../components/DNASection/DNASection"
import HeroSection from "../components/HeroSection/HeroSection"
import SEO from "../components/seo"
import { certifyMyHerdContent } from "../content/certifyMyHerd"
import ParallaxSection from "../components/ParallaxSection/ParallaxSection"

const CertifyMyHerd = data => {
  return (
    <React.Fragment>
      <SEO title="Certify my Herd" />
      <HeroSection {...certifyMyHerdContent.heroSection} />
      <BlackTextVideoSection {...certifyMyHerdContent.blackTextVideoSection} />
      <ParallaxSection {...certifyMyHerdContent.parallax} />
      <DNASection {...data.data} />
    </React.Fragment>
  )
}

export default CertifyMyHerd

export const query = graphql`
  query ProductQuery {
    allContentfulProduct(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          name
          slug
          rating
          image {
            fluid(maxWidth: 1000) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          price
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    }
  }
`
