export const dnaSectionBulletsInfo = [
  {
    bullet: "Place blood sample of each animal you wish to test",
  },
  {
    bullet: "Fill out form that is sent with certification kits",
  },
  {
    bullet: "Follow instructions as indicated on each card",
  },
  {
    bullet: `Send in samples with form to:\n
    Tenet Certification \n
   114 E. Naches ave. Ste 56\n
   Selah, WA 98942`,
  },
]

export const dnaSectionNormalInfo = [
  {
    index: 0,
    content:
      "Results for Tenet™, industry certified beef will be emailed and signed certificate will be mailed to the address provided on the form.",
  },
  {
    index: 1,
    content: "Results can be expected within 14 business days of lab delivery.",
  },
  {
    index: 2,
    content: "Questions? Call Callipyge Genetics at 509.907.7507",
  },
]

export const dnaSectionSteps = [
  {
    step: 1,
    headerText: "ORDER",
    displayText:
      "Order your Tenet™ certification sample cards below.  Purchased sample cards will arrive in the US mail and include record sheets. " +
      "We do not sell Allflex™ tissue sample unit tubes.",
  },
  {
    step: 2,
    headerText: "DOCUMENT",
    displayText:
      "Collect blood samples following the directions provided on each Tenet™ certification sample card.  The test requires 3 or 4 drops of blood on the special paper in the sample card.  Fill the circle with blood.  Keep the sample clean and dry.  Common problems with test samples include not enough blood to test and dirty blood samples. Fill out the form and include animal identification. If using Allflex™ tissue sample unit, please follow instructions provided by Allflex™.",
  },
  {
    step: 3,
    headerText: "PAYMENT",
    displayText:
      "Tenet™ certification sample cards are tested at our laboratory. The sample cards and lab tests are sold separately. Order your cards on the website. The cost per Tenet™ Test is $20.00 and the blood cards are $1.50 per card. We do not sell Allflex™ tissue sample units. ",
  },
  {
    step: 4,
    headerText: "RESULTS",
    displayText:
      "Callipyge Genetics will email producers the tenderness certification results",
  },
]
