import React from "react"
import Image from "../Image/image"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import classes from "./DNASection.module.css"
import { dnaSectionSteps } from "./DNASectionInfo"
import Products from "../Products/Products"

const DNASection = props => {
  return (
    <React.Fragment>
      <FullWidthYellowDivider />
      <section className={classes.sectionContainer}>
        <div className={classes.productProcessContainer}>
          <h3 className={classes.productProcessHeader}>
            PRODUCT AND PROCESS OVERVIEW
          </h3>
          <div className={classes.blackDivider} />
          <p className={classes.produceProcessText}>
            Our certification process requires blood samples, (3 or 4 drops)
            from each animal. Our Tenet™ certification sample cards have
            distinctive paper, allowing DNA extraction to be completed. We
            designed Tenet™ sample cards for our test. We also understand there
            are other sampling methods such as tissue samples using the Allflex™
            system. We do accept tissue samples using the Allflex™ system for
            Tenet™, but an individual animal identification, preferably an EID
            number will be required for certification.
          </p>
        </div>
        <div className={classes.stepsContainer}>
          {dnaSectionSteps.map((step, index) => (
            <div key={index} className={classes.stepWrapper}>
              <h3 className={classes.stepHeader}>STEP {step.step}</h3>
              <div className={classes.stepBlackDivider} />
              <h3 className={classes.stepHeader}>{step.headerText}</h3>
              <p className={classes.stepText}>{step.displayText}</p>
            </div>
          ))}
        </div>
        <div className={classes.skuListContainer}>
          <h3 className={classes.productProcessHeader}>ORDER</h3>
          <div className={classes.blackDivider} />
          <Products {...props} />
          <div className={classes.orderTextContainer}>
            <div className={classes.statementContainer}>
              <p className={classes.produceProcessText}>
                By ordering TENET™ Certification Cards and/or a TENET™
                Certification Test, Purchaser acknowledges and agrees that:
              </p>
              <ol className={classes.mt15}>
                <li>
                  Any test results provided will characterize the tenderness
                  rating only for the specific animal tested, and will indicate
                  whether the tested animal meets the qualification standard for
                  TENET® brand products.
                </li>
                <li>
                  The TENET® trademark may not be used by Purchaser in the
                  production, labeling, advertising or sale of cattle, beef,
                  embryos or semen unless specifically licensed in writing, in
                  advance, by Callipyge Genetics, LLC.
                </li>
                <li>
                  Use of the TENET® trademark is restricted to licensed users,
                  and may be subject to the payment of additional fees.{" "}
                </li>
              </ol>
              <p className={classes.produceProcessText}>
                For additional information regarding licensing, contact
                Callipyge Genetics, LLC at [
                <a
                  href="mailto:callipygegenomics@gmail.com"
                  className={classes.linkStyle}
                >
                  callipygegenomics@gmail.com
                </a>
                ]. The TENET® trademark and related proprietary technology are
                the sole and exclusive property of Callipyge Genetics, LLC. All
                rights reserved.
              </p>
            </div>
            <p className={classes.produceProcessText}>
              Producers wanting information on Tenet™ Certified AI sires and
              Tenet™ Certified embryos please email{" "}
              <a
                href="mailto:tenetbeef@gmail.com"
                className={classes.linkStyle}
              >
                tenetbeef@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className={classes.skuListContainer}>
          <h3 className={classes.productProcessHeader}>FORMS</h3>
          <p className={classes.formsInfoText}>
            We will send a form with your Tenet™ Certification Cards. Please
            click on the below PDFs to download and print additional forms.
          </p>
          <div className={classes.blackDivider} />
          <div className={classes.formsContainer}>
            <div className={classes.formWrapper}>
              <a
                href="/OrderForm.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image filename="OrderForm_Site.png" />
              </a>
            </div>
            <div className={classes.formWrapper}>
              <a
                href="/OrderFormCont.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image filename="OrderFormCont_Site.png" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
}

export default DNASection
