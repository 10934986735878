import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import classes from "./Products.module.css"

const Products = data => {
  return (
    <React.Fragment>
      <div className={[classes.productContainer, "product-main"].join(" ")}>
        {data.allContentfulProduct.edges.map(items => (
          <div
            className={[classes.itemContainer, "Catalogue__item"].join(" ")}
            key={items.node.id}
          >
            <div className="details_List">
              {items.node.image === null ? (
                <div className="no-image">No Image</div>
              ) : (
                <Img
                  sizes={items.node.image.fluid}
                  className={classes.productImage}
                />
              )}

              <center>
                <div className={[classes.details, "details_inner"].join(" ")}>
                  <h2 className={classes.productName}>{items.node.name}</h2>
                  <div className={classes.blackDivider} />
                  {items.node.slug !== "/certification-test" && (
                    <p className={classes.productDescription}>
                      {items.node.details.childMarkdownRemark.excerpt}
                    </p>
                  )}
                </div>
              </center>
              <div className={classes.itemPriceButton}>
                <div className={classes.buttonContainer}>
                  <Link to={`${items.node.slug}`}>
                    <button className={classes.productButton}>ORDER</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

export default Products
