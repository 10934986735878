import certifyHero from "../images/Certify_Header_BG.jpg"
import classes from "../components/HeroSection/HeroSection.module.css"
import indexBlackText from '../images/black-text-section.jpg'
import certifyParallax from "../images/Certify_Parralax.jpg"

export const certifyMyHerdContent = {
    heroSection: {
        sectionId: "CERTIFY MY HERD",
        background: certifyHero,
        heroImageClass: "headerImageClass",
        imageFile: "cow.png",
        alt: "cow",
        headerText: "CERTIFY MY HERD",
        displayText: "THE FIRST NATURALLY SELECTED, DNA AUTHENTICATED BEEF TENDERNESS RATING",
        bottomImage: "Certify_Beef.png",
        bottomImageClass: classes.certifyImageWrapper
    },
    blackTextVideoSection: {
        sectionId: "guaranteed-tenderness",
        background: indexBlackText,
        displayText: [
            {
                text: "Guaranteed tenderness at your fingertips.  Whether you have 5 or 50,000 cattle, " +
                  "higher quality tenderness starts here.",
                color: "rgba(255, 255, 255, 1)"
            },
        ],
    },
    parallax: {
        sectionId: 'certify-my-herd-parallax',
        background: certifyParallax,
        alt: 'parallax-one-image',
    },
}
